var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8",
      "cols": "12",
      "order": "1",
      "order-lg": "2"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "align-items-center mb-1"
  }, [_c('h3', [_vm._v("Data Pribadi")]), _c('div', {
    staticClass: "mt-2 text-center"
  }, [_c('b-avatar', {
    staticClass: "align-baseline img-fluid",
    attrs: {
      "button": "",
      "size": "90",
      "src": _vm.viewAvatar
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.fileInput.click();
      }
    }
  }), _c('input', {
    ref: "fileInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "enctype": "multipart/form-data",
      "accept": "image/*"
    },
    on: {
      "change": _vm.fileSelected
    }
  })], 1), _c('hr', {
    staticClass: "mt-2"
  })]), _c('validation-observer', {
    ref: "profil",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "profil mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.simpan($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nama Lengkap",
            "label-for": "nama_lengkap"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Nama Lengkap",
            "vid": "nama_lengkap",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "nama_lengkap",
                  "state": errors.length > 0 ? false : null,
                  "name": "nama_lengkap",
                  "placeholder": "Masukkan Nama Lengkap"
                },
                model: {
                  value: _vm.form.nama_lengkap,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "nama_lengkap", $$v);
                  },
                  expression: "form.nama_lengkap"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Asal Sekolah",
            "label-for": "asal_sekolah"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Asal Sekolah",
            "vid": "asal_sekolah",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('v-select', {
                attrs: {
                  "label": "nama_sekolah",
                  "options": _vm.optionsSekolah,
                  "state": errors.length > 0 ? false : null,
                  "name": "asal_sekolah",
                  "placeholder": "Pilih"
                },
                model: {
                  value: _vm.form.asal_sekolah,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "asal_sekolah", $$v);
                  },
                  expression: "form.asal_sekolah"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Asal Kota",
            "label-for": "asal_kota"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Asal Kota",
            "vid": "asal_kota",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('v-select', {
                attrs: {
                  "label": "nama_kabupaten",
                  "options": _vm.options,
                  "state": errors.length > 0 ? false : null,
                  "name": "asal_kota",
                  "placeholder": "Pilih"
                },
                model: {
                  value: _vm.form.asal_kota,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "asal_kota", $$v);
                  },
                  expression: "form.asal_kota"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "Username",
            "label-for": "username"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Username",
            "vid": "username",
            "rules": "required|alpha-dash"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "username",
                  "state": errors.length > 0 ? false : null,
                  "name": "username",
                  "placeholder": "Masukkan username",
                  "readonly": ""
                },
                model: {
                  value: _vm.form.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "username", $$v);
                  },
                  expression: "form.username"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Email Kamu",
            "label-for": "email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email Kamu",
            "vid": "email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "state": errors.length > 0 ? false : null,
                  "name": "email",
                  "placeholder": "Masukkan email kamu",
                  "readonly": ""
                },
                model: {
                  value: _vm.form.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "email", $$v);
                  },
                  expression: "form.email"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomer Telepon/Whatsapp",
            "label-for": "no_telp"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Nomer Telepon/Whatsapp",
            "vid": "no_telp",
            "rules": "required|integer|min:11"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "no_telp",
                  "state": errors.length > 0 ? false : null,
                  "name": "no_telp",
                  "placeholder": "Masukkan nomor telepon"
                },
                model: {
                  value: _vm.form.no_telp,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "no_telp", $$v);
                  },
                  expression: "form.no_telp"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "Tanggal Lahir",
            "label-for": "tgl_lahir"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Tanggal Lahir",
            "vid": "tgl_lahir",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('flat-pickr', {
                staticClass: "form-control",
                attrs: {
                  "id": "tgl_lahir",
                  "state": errors.length > 0 ? false : null,
                  "name": "tgl_lahir",
                  "config": {
                    altInput: true,
                    altFormat: 'd-m-Y',
                    dateFormat: 'Y-m-d'
                  },
                  "placeholder": "Masukkan tanggal lahir"
                },
                model: {
                  value: _vm.form.tgl_lahir,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "tgl_lahir", $$v);
                  },
                  expression: "form.tgl_lahir"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "Nama Ayah",
            "label-for": "nama_ayah"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Nama Ayah",
            "vid": "nama_ayah",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "nama_ayah",
                  "state": errors.length > 0 ? false : null,
                  "name": "nama_ayah",
                  "placeholder": "Masukkan nama ayah"
                },
                model: {
                  value: _vm.form.nama_ayah,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "nama_ayah", $$v);
                  },
                  expression: "form.nama_ayah"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomer Telepon/Whatsapp Ayah",
            "label-for": "no_telp_ayah"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Nomer Telepon/Whatsapp Ayah",
            "vid": "no_telp_ayah",
            "rules": "required|integer|min:11"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "no_telp_ayah",
                  "state": errors.length > 0 ? false : null,
                  "name": "no_telp_ayah",
                  "placeholder": "Masukkan nomor telepon ayah"
                },
                model: {
                  value: _vm.form.no_telp_ayah,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "no_telp_ayah", $$v);
                  },
                  expression: "form.no_telp_ayah"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Profesi Ayah",
            "label-for": "profesi_ayah"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Profesi Ayah",
            "vid": "profesi_ayah",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "profesi_ayah",
                  "state": errors.length > 0 ? false : null,
                  "name": "profesi_ayah",
                  "placeholder": "Masukkan profesi ayah"
                },
                model: {
                  value: _vm.form.profesi_ayah,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "profesi_ayah", $$v);
                  },
                  expression: "form.profesi_ayah"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "Nama Ibu",
            "label-for": "nama_ibu"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Nama Ibu",
            "vid": "nama_ibu",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "nama_ibu",
                  "state": errors.length > 0 ? false : null,
                  "name": "nama_ibu",
                  "placeholder": "Masukkan nama ibu"
                },
                model: {
                  value: _vm.form.nama_ibu,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "nama_ibu", $$v);
                  },
                  expression: "form.nama_ibu"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomer Telepon/Whatsapp ibu",
            "label-for": "no_telp_ibu"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Nomer Telepon/Whatsapp ibu",
            "vid": "no_telp_ibu",
            "rules": "required|integer|min:11"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "no_telp_ibu",
                  "state": errors.length > 0 ? false : null,
                  "name": "no_telp_ibu",
                  "placeholder": "Masukkan nomor telepon ibu"
                },
                model: {
                  value: _vm.form.no_telp_ibu,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "no_telp_ibu", $$v);
                  },
                  expression: "form.no_telp_ibu"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Profesi Ibu",
            "label-for": "profesi_ibu"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Profesi Ibu",
            "vid": "profesi_ibu",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "profesi_ibu",
                  "state": errors.length > 0 ? false : null,
                  "name": "profesi_ibu",
                  "placeholder": "Masukkan profesi ibu"
                },
                model: {
                  value: _vm.form.profesi_ibu,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "profesi_ibu", $$v);
                  },
                  expression: "form.profesi_ibu"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-button', {
          staticClass: "mt-2",
          attrs: {
            "type": "submit",
            "variant": "primary",
            "block": "",
            "disabled": invalid
          }
        }, [_vm._v(" Simpan ")])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }