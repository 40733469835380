var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-tabs', {
    attrs: {
      "vertical": "",
      "nav-wrapper-class": "nav-vertical"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Data Pribadi"
    }
  }, [_c('DataPribadi')], 1), _c('b-tab', {
    attrs: {
      "title": "Ganti Kata Sandi"
    }
  }, [_c('GantiSandi')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }