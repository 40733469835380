var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8",
      "cols": "12",
      "order": "1",
      "order-lg": "2"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "align-items-center mb-1"
  }, [_c('h3', [_vm._v("Ganti Kata Sandi")])]), _c('validation-observer', {
    ref: "resetPassword",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-reset-password-form mt-2",
          attrs: {
            "method": "POST"
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Kata Sandi Lama",
            "label-for": "kata_sandi_lama"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Kata Sandi Lama",
            "vid": "kata_sandi_lama",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "kata_sandi_lama",
                  "type": _vm.passwordFieldTypeL,
                  "state": errors.length > 0 ? false : null,
                  "name": "kata_sandi_lama",
                  "placeholder": "Masukkan Kata Sandi Lama"
                },
                model: {
                  value: _vm.old_password,
                  callback: function callback($$v) {
                    _vm.old_password = $$v;
                  },
                  expression: "old_password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconL
                },
                on: {
                  "click": _vm.togglePasswordVisibilityL
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "Kata Sandi Baru",
            "label-for": "kata_sandi_baru"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Kata Sandi Baru",
            "vid": "kata_sandi_baru",
            "rules": "required|min:8"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "kata_sandi_baru",
                  "type": _vm.passwordFieldTypeB,
                  "state": errors.length > 0 ? false : null,
                  "name": "kata_sandi_baru",
                  "placeholder": "Masukkan Kata Sandi Baru"
                },
                model: {
                  value: _vm.new_password,
                  callback: function callback($$v) {
                    _vm.new_password = $$v;
                  },
                  expression: "new_password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconB
                },
                on: {
                  "click": _vm.togglePasswordVisibilityB
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "ckata_sandi_baru",
            "label": "Konfirmasi Kata Sandi Baru"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Konfirmasi Kata Sandi Baru",
            "rules": "required|confirmed:kata_sandi_baru"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "ckata_sandi_baru",
                  "type": _vm.passwordFieldTypeK,
                  "state": errors.length > 0 ? false : null,
                  "name": "ckata_sandi_baru",
                  "placeholder": "Konfirmasi Kata Sandi Baru"
                },
                model: {
                  value: _vm.cNew_password,
                  callback: function callback($$v) {
                    _vm.cNew_password = $$v;
                  },
                  expression: "cNew_password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconK
                },
                on: {
                  "click": _vm.togglePasswordVisibilityK
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-button', {
          attrs: {
            "block": "",
            "type": "submit",
            "disabled": invalid,
            "variant": "primary"
          }
        }, [_vm._v(" Simpan ")])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }